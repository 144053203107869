(function ($) {
    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();

        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    var wcCheckoutPayForm = {
        updateTimer: false,
        dirtyInput: false,
        selectedPaymentMethod: false,
        xhr: false,
        $order_review: $('#order_review'),
        $checkout_form: $('form.checkout-pay'),
        init: function () {
            $(document.body).on('update_checkout_pay', this.update_checkout_pay);
            $(document.body).on('init_checkout_pay', this.init_checkout_pay);

            // Payment methods
            this.$checkout_form.on('click', 'input[name="payment_method"]', this.payment_method_selected);

            if ($(document.body).hasClass('woocommerce-order-pay')) {
                this.$order_review.on('click', 'input[name="payment_method"]', this.payment_method_selected);
                this.$order_review.on('submit', this.submitOrder);
                this.$order_review.attr('novalidate', 'novalidate');
            }

            // Prevent HTML5 validation which can conflict.
            this.$checkout_form.attr('novalidate', 'novalidate');

            // Form submissio

            // Inline validation
            this.$checkout_form.on('input validate change', '.input-text, select, input:checkbox', this.validate_field);

            // Manual trigger
            this.$checkout_form.on('update', this.trigger_update_checkout_pay);

            // Inputs/selects which update totals
            this.$checkout_form.on('change', 'select.shipping_method, input[name^="shipping_method"], #ship-to-different-address input, .update_totals_on_change select, .update_totals_on_change input[type="radio"], .update_totals_on_change input[type="checkbox"]', this.trigger_update_checkout_pay); // eslint-disable-line max-len
            this.$checkout_form.on('change', '.address-field select', this.input_changed);
            this.$checkout_form.on('change', '.address-field input.input-text, .update_totals_on_change input.input-text', this.maybe_input_changed); // eslint-disable-line max-len
            this.$checkout_form.on('keydown', '.address-field input.input-text, .update_totals_on_change input.input-text', this.queue_update_checkout_pay); // eslint-disable-line max-len

            // Address fields
            this.$checkout_form.on('change', '#ship-to-different-address input', this.ship_to_different_address);

            // Trigger events
            this.$checkout_form.find('#ship-to-different-address input').trigger('change');
            this.init_payment_methods();

            // Update on page load
            if (wc_checkout_params.is_checkout === '1') {
                $(document.body).trigger('init_checkout_pay');
            }
            if (wc_checkout_params.option_guest_checkout === 'yes') {
                $('input#createaccount').on('change', this.toggle_create_account).trigger('change');
            }
        },
        init_payment_methods: function () {
            var $payment_methods = $('.woocommerce-checkout').find('input[name="payment_method"]');

            // If there is one method, we can hide the radio input
            if (1 === $payment_methods.length) {
                $payment_methods.eq(0).hide();
            }

            // If there was a previously selected method, check that one.
            if (wcCheckoutPayForm.selectedPaymentMethod) {
                $('#' + wcCheckoutPayForm.selectedPaymentMethod).prop('checked', true);
            }

            // If there are none selected, select the first.
            if (0 === $payment_methods.filter(':checked').length) {
                $payment_methods.eq(0).prop('checked', true);
            }

            // Get name of new selected method.
            var checkedPaymentMethod = $payment_methods.filter(':checked').eq(0).prop('id');

            if ($payment_methods.length > 1) {
                // Hide open descriptions.
                $('div.payment_box:not(".' + checkedPaymentMethod + '")').filter(':visible').slideUp(0);
            }

            // Trigger click event for selected method
            $payment_methods.filter(':checked').eq(0).trigger('click');
        },
        get_payment_method: function () {
            return wcCheckoutPayForm.$checkout_form.find('input[name="payment_method"]:checked').val();
        },
        get_order_id: function () {
            return wcCheckoutPayForm.$checkout_form.data('wc-order');
        },
        get_order_key: function () {
            return wcCheckoutPayForm.$checkout_form.data('wc-order-key');
        },
        payment_method_selected: function (e) {
            e.stopPropagation();

            if ($('.payment_methods input.input-radio').length > 1) {
                var target_payment_box = $('div.payment_box.' + $(this).attr('ID')),
                    is_checked = $(this).is(':checked');

                if (is_checked && !target_payment_box.is(':visible')) {
                    $('div.payment_box').filter(':visible').slideUp(230);

                    if (is_checked) {
                        target_payment_box.slideDown(230);
                    }
                }
            } else {
                $('div.payment_box').show();
            }

            if ($(this).data('order_button_text')) {
                $('#place_order').text($(this).data('order_button_text'));
            } else {
                $('#place_order').text($('#place_order').data('value'));
            }

            var selectedPaymentMethod = $('.woocommerce-checkout input[name="payment_method"]:checked').attr('id');

            if (selectedPaymentMethod !== wcCheckoutPayForm.selectedPaymentMethod) {
                $(document.body).trigger('payment_method_selected');
            }

            wcCheckoutPayForm.selectedPaymentMethod = selectedPaymentMethod;
        },
        toggle_create_account: function () {
            $('div.create-account').hide();

            if ($(this).is(':checked')) {
                // Ensure password is not pre-populated.
                $('#account_password').val('').trigger('change');
                $('div.create-account').slideDown();
            }
        },
        init_checkout_pay: function () {
            $(document.body).trigger('update_checkout_pay');
        },
        maybe_input_changed: function (e) {
            if (wcCheckoutPayForm.dirtyInput) {
                wcCheckoutPayForm.input_changed(e);
            }
        },
        input_changed: function (e) {
            wcCheckoutPayForm.dirtyInput = e.target;
            wcCheckoutPayForm.maybe_update_checkout_pay();
        },
        queue_update_checkout_pay: function (e) {
            var code = e.keyCode || e.which || 0;

            if (code === 9) {
                return true;
            }

            wcCheckoutPayForm.dirtyInput = this;
            wcCheckoutPayForm.reset_update_checkout_pay_timer();
            wcCheckoutPayForm.updateTimer = setTimeout(wcCheckoutPayForm.maybe_update_checkout_pay, '1000');
        },
        trigger_update_checkout_pay: function () {
            wcCheckoutPayForm.reset_update_checkout_pay_timer();
            wcCheckoutPayForm.dirtyInput = false;
            $(document.body).trigger('update_checkout_pay');
        },
        maybe_update_checkout_pay: function () {
            var update_totals = true;

            if ($(wcCheckoutPayForm.dirtyInput).length) {
                var $required_inputs = $(wcCheckoutPayForm.dirtyInput).closest('div').find('.address-field.validate-required');

                if ($required_inputs.length) {
                    $required_inputs.each(function () {
                        if ($(this).find('input.input-text').val() === '') {
                            update_totals = false;
                        }
                    });
                }
            }
            if (update_totals) {
                wcCheckoutPayForm.trigger_update_checkout_pay();
            }
        },
        ship_to_different_address: function () {
            $('div.shipping_address').hide();
            if ($(this).is(':checked')) {
                $('div.shipping_address').slideDown();
            }
        },
        reset_update_checkout_pay_timer: function () {
            clearTimeout(wcCheckoutPayForm.updateTimer);
        },
        is_valid_json: function (raw_json) {
            try {
                var json = JSON.parse(raw_json);

                return (json && 'object' === typeof json);
            } catch (e) {
                return false;
            }
        },
        validate_field: function (e) {
            var $this = $(this),
                $parent = $this.closest('.form-row'),
                validated = true,
                validate_required = $parent.is('.validate-required'),
                validate_email = $parent.is('.validate-email'),
                validate_phone = $parent.is('.validate-phone'),
                pattern = '',
                event_type = e.type;

            if ('input' === event_type) {
                $parent.removeClass('woocommerce-invalid woocommerce-invalid-required-field woocommerce-invalid-email woocommerce-invalid-phone woocommerce-validated'); // eslint-disable-line max-len
            }

            if ('validate' === event_type || 'change' === event_type) {

                if (validate_required) {
                    if ('checkbox' === $this.attr('type') && !$this.is(':checked')) {
                        $parent.removeClass('woocommerce-validated').addClass('woocommerce-invalid woocommerce-invalid-required-field');
                        validated = false;
                    } else if ($this.val() === '') {
                        $parent.removeClass('woocommerce-validated').addClass('woocommerce-invalid woocommerce-invalid-required-field');
                        validated = false;
                    }
                }

                if (validate_email) {
                    if ($this.val()) {
                        /* https://stackoverflow.com/questions/2855865/jquery-validate-e-mail-address-regex */
                        pattern = new RegExp(/^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[0-9a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i); // eslint-disable-line max-len

                        if (!pattern.test($this.val())) {
                            $parent.removeClass('woocommerce-validated').addClass('woocommerce-invalid woocommerce-invalid-email woocommerce-invalid-phone'); // eslint-disable-line max-len
                            validated = false;
                        }
                    }
                }

                if (validate_phone) {
                    pattern = new RegExp(/[\s\#0-9_\-\+\/\(\)\.]/g);

                    if (0 < $this.val().replace(pattern, '').length) {
                        $parent.removeClass('woocommerce-validated').addClass('woocommerce-invalid woocommerce-invalid-phone');
                        validated = false;
                    }
                }

                if (validated) {
                    $parent.removeClass('woocommerce-invalid woocommerce-invalid-required-field woocommerce-invalid-email woocommerce-invalid-phone').addClass('woocommerce-validated'); // eslint-disable-line max-len
                }
            }
        },
        update_checkout_pay: function (event, args) {
            // Small timeout to prevent multiple requests when several fields update at the same time
            wcCheckoutPayForm.reset_update_checkout_pay_timer();
            wcCheckoutPayForm.updateTimer = setTimeout(wcCheckoutPayForm.update_checkout_pay_action, '5', args);
        },
        update_checkout_pay_action: function (args) {
            if (wcCheckoutPayForm.xhr) {
                wcCheckoutPayForm.xhr.abort();
            }

            if ($('form.checkout-pay').length === 0) {
                return;
            }

            args = typeof args !== 'undefined' ? args : {
                update_shipping_method: true
            };

            var country = $('#billing_country').val(),
                state = $('#billing_state').val(),
                postcode = $(':input#billing_postcode').val(),
                city = $('#billing_city').val(),
                address = $(':input#billing_address_1').val(),
                address_2 = $(':input#billing_address_2').val(),
                s_country = country,
                s_state = state,
                s_postcode = postcode,
                s_city = city,
                s_address = address,
                s_address_2 = address_2,
                $required_inputs = $(wcCheckoutPayForm.$checkout_form).find('.address-field.validate-required:visible'),
                has_full_address = true;

            if ($required_inputs.length) {
                $required_inputs.each(function () {
                    if ($(this).find(':input').val() === '') {
                        has_full_address = false;
                    }
                });
            }

            if ($('#ship-to-different-address').find('input').is(':checked')) {
                s_country = $('#shipping_country').val();
                s_state = $('#shipping_state').val();
                s_postcode = $(':input#shipping_postcode').val();
                s_city = $('#shipping_city').val();
                s_address = $(':input#shipping_address_1').val();
                s_address_2 = $(':input#shipping_address_2').val();
            }

            var data = {
                security: wc_checkout_params.update_order_review_nonce,
                payment_method: wcCheckoutPayForm.get_payment_method(),
                country: country,
                state: state,
                postcode: postcode,
                city: city,
                address: address,
                address_2: address_2,
                s_country: s_country,
                s_state: s_state,
                s_postcode: s_postcode,
                s_city: s_city,
                s_address: s_address,
                s_address_2: s_address_2,
                has_full_address: has_full_address,
                post_data: $('form.checkout-pay').serializeObject()
            };

            if (args.update_shipping_method !== false) {
                var shipping_methods = {};

                // eslint-disable-next-line max-len
                $('select.shipping_method, input[name^="shipping_method"][type="radio"]:checked, input[name^="shipping_method"][type="hidden"]').each(function () {
                    shipping_methods[$(this).data('index')] = $(this).val();
                });

                data.shipping_method = shipping_methods;
            }

            $('.woocommerce-checkout-payment, .woocommerce-checkout-review-order-table').block({
                message: null,
                overlayCSS: {
                    background: '#fff',
                    opacity: 0.6
                }
            });

            wcCheckoutPayForm.xhr = $.ajax({
                type: 'POST',
                url: wc_checkout_params.wc_ajax_url.toString().replace('%%endpoint%%', 'update_order_pay_review') + `&order=${wcCheckoutPayForm.get_order_id()}&key=${wcCheckoutPayForm.get_order_key()}&pay_for_order=true`,
                data: data,
                success: function (data) {
                    // Reload the page if requested
                    if (data && true === data.reload) {
                        window.location.reload();
                        return;
                    }

                    // Remove any notices added previously
                    $('.woocommerce-NoticeGroup-updateOrderReview').remove();

                    var termsCheckBoxChecked = $('#terms').prop('checked');

                    // Save payment details to a temporary object
                    var paymentDetails = {};
                    $('.payment_box :input').each(function () {
                        var ID = $(this).attr('id');

                        if (ID) {
                            if ($.inArray($(this).attr('type'), ['checkbox', 'radio']) !== -1) {
                                paymentDetails[ID] = $(this).prop('checked');
                            } else {
                                paymentDetails[ID] = $(this).val();
                            }
                        }
                    });

                    if (data && data['checkout-pay']) {
                        let $data = $(data['checkout-pay']);

                        const fragments = {
                            '.woocommerce-checkout-payment': $data.find('.woocommerce-checkout-payment').html(),
                            '.woocommerce-checkout-review-order-table': $data.find('.woocommerce-checkout-review-order-table').html(),
                        };

                        $.each(fragments, function (key, value) {
                            if (!wcCheckoutPayForm.fragments || wcCheckoutPayForm.fragments[key] !== value) {
                                $(key).html(value);
                            }

                            $(key).unblock();
                        });

                        wcCheckoutPayForm.fragments = data.fragments;
                    }

                    // Recheck the terms and conditions box, if needed
                    if (termsCheckBoxChecked) {
                        $('#terms').prop('checked', true);
                    }

                    // Fill in the payment details if possible without overwriting data if set.
                    if (!$.isEmptyObject(paymentDetails)) {
                        $('.payment_box :input').each(function () {
                            var ID = $(this).attr('id');
                            if (ID) {
                                if ($.inArray($(this).attr('type'), ['checkbox', 'radio']) !== -1) {
                                    $(this).prop('checked', paymentDetails[ID]).trigger('change');
                                } else if ($.inArray($(this).attr('type'), ['select']) !== -1) {
                                    $(this).val(paymentDetails[ID]).trigger('change');
                                } else if (null !== $(this).val() && 0 === $(this).val().length) {
                                    $(this).val(paymentDetails[ID]).trigger('change');
                                }
                            }
                        });
                    }

                    // Add new errors returned by this event
                    // Remove notices from all sources
                    const $form = $('form.checkout-pay');

                    $('.woocommerce-error, .woocommerce-message').remove();

                    if (data && data.messages) {
                        $form.prepend('<div class="woocommerce-NoticeGroup woocommerce-NoticeGroup-updateOrderReview">' + data.messages + '</div>'); // eslint-disable-line max-len
                    } else {
                        $form.prepend(data);
                    }

                    // Check for error
                    if (data && data.result === 'failure') {
                        // Lose focus for all fields
                        $form.find('.input-text, select, input:checkbox').trigger('validate').trigger('blur');

                        $form.find('.woocommerce-checkout-payment').html('');

                        wcCheckoutPayForm.scroll_to_notices();
                    }

                    // Re-init methods
                    wcCheckoutPayForm.init_payment_methods();

                    // Fire updated_checkout event.
                    $(document.body).trigger('updated_checkout_pay', [data]);
                }

            });
        },
        blockOnSubmit: function ($form) {
            var isBlocked = $form.data('blockUI.isBlocked');

            if (1 !== isBlocked) {
                $form.block({
                    message: null,
                    overlayCSS: {
                        background: '#fff',
                        opacity: 0.6
                    }
                });
            }
        },
        submitOrder: function () {
            wcCheckoutPayForm.blockOnSubmit($(this));
        },
        submit: function () {
            wcCheckoutPayForm.reset_update_checkout_pay_timer();
            var $form = $(this);

            if ($form.is('.processing')) {
                return false;
            }
            console.log("hola que tal");

            $form.addClass('processing');
            wcCheckoutPayForm.blockOnSubmit($form);

            $form.submit();

            return false;
        },
        submit_error: function (error_message) {
            $('.woocommerce-NoticeGroup-checkout, .woocommerce-error, .woocommerce-message').remove();
            wcCheckoutPayForm.$checkout_form.prepend('<div class="woocommerce-NoticeGroup woocommerce-NoticeGroup-checkout">' + error_message + '</div>'); // eslint-disable-line max-len
            wcCheckoutPayForm.$checkout_form.removeClass('processing').unblock();
            wcCheckoutPayForm.$checkout_form.find('.input-text, select, input:checkbox').trigger('validate').trigger('blur');
            wcCheckoutPayForm.scroll_to_notices();
            $(document.body).trigger('checkout_error', [error_message]);
        },
        scroll_to_notices: function () {
            var scrollElement = $('.woocommerce-NoticeGroup-updateOrderReview, .woocommerce-NoticeGroup-checkout');

            if (!scrollElement.length) {
                scrollElement = $('form.checkout-pay');
            }
            $.scroll_to_notices(scrollElement);
        }
    };

    $(function () {
        wcCheckoutPayForm.init();

        const displayShipping = function ($check) {
            $('div.shipping_address').hide();

            if ($check && $check.is(':checked')) {
                $('div.shipping_address').slideDown();
            }
        }

        if ($('#order_review').length > 0) {
            displayShipping();

            $('#order_review').on('change', '#ship-to-different-address input', function () {
                displayShipping($(this));
            });
        }
    });
})(window.jQuery);
